<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-06-06 10:19:22
 * @LastEditors: pj
 * @LastEditTime: 2023-06-06 12:38:31
-->

<template>
  <div>
    <gl-title title="抽奖设置"></gl-title>
    <!-- <div style="margin: 18px">
      <el-button
        v-check="'107031'"
        type="primary"
        size="mini"
        @click="mClickAction('mUpdateVisible')"
      >
        新增
      </el-button>
    </div> -->
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column
          type="index"
          :index="mIndexMethod"
          label="序号"
          align="center"
          min-width="30"
        ></el-table-column>
        <el-table-column
          prop="awardNameSimple"
          label="奖励名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="integral"
          label="喜糖"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="probability" label="中奖概率"></el-table-column>

        <el-table-column prop="isEntity" label="奖品类型">
          <template slot-scope="scope">
            <span>{{ scope.row.isEntity == 1 ? '实体奖品' : '喜糖奖励' }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="awardImage" label="奖品图片">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.awardImage"
              fit="cover"
              :preview-src-list="[scope.row.awardImage]"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-check="'107032'"
              type="text"
              @click="mClickAction('mUpdateVisible', scope.row.id)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              @click="mClickAction('mPreviewVisible', scope.row.id)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </gl-card>
    <update
      :visible.sync="mUpdateVisible"
      :itemId="mItemId"
      @reload="mReload"
    />
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId" />
  </div>
</template>

<script>
import { listLuckyDrawRotaryTable } from '@/api/coox/lottery'
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'

export default {
  name: 'LotterySetting',
  components: { update, preview },
  mixins: [ListMixin],
  mounted() {
    this.mGetListFun = listLuckyDrawRotaryTable
    this.mGetList()
  },
}
</script>

<style></style>
