<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-06-06 11:32:05
 * @LastEditors: pj
 * @LastEditTime: 2023-06-06 16:43:13
-->
<template>
  <el-dialog
    :title="itemId ? '修改' : '新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form
        :model="formData"
        ref="form"
        label-width="120px"
        size="mini"
        v-loading="loading"
      >
        <el-form-item prop="awardImage" label="奖品图" verify>
          <gl-upload-images
            v-model="formData.awardImage"
            :limit="1"
            :oldList="oldList"
          ></gl-upload-images>
        </el-form-item>
        <el-form-item prop="awardNameSimple" label="奖项名称" verify>
          <el-input
            v-model.number="formData.awardNameSimple"
            placeholder="请输入奖项名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="integral" label="喜糖数" verify>
          <el-input
            v-model.number="formData.integral"
            placeholder="请输入喜糖数"
          ></el-input>
        </el-form-item>
        <el-form-item prop="probability" label="中奖概率" verify>
          <el-input
            v-model.number="formData.probability"
            placeholder="请输入中奖概率"
          ></el-input>
        </el-form-item>
        <!--做一个下拉选项，初始值formData.isEntity,默认值1为实体奖品，0为喜糖奖励，传入formData.isEntity， 1为实体奖品，0为喜糖奖励-->
        <el-form-item prop="isEntity" label="奖品类型" verify>
          <el-select v-model="formData.isEntity" placeholder="请选择奖品类型">
            <el-option label="实体奖品" :value="1"></el-option>
            <el-option label="喜糖奖励" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {
  getBannerById,
  addBanner,
  updateBanner,
} from '@/api/backendall/systemSetup'

import {
  getLuckyDrawRotaryTableById,
  updateLuckyDrawRotaryTable,
} from '@/api/coox/lottery'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      oldList: [],
      formData: {
        awardNameSimple: null,
        integral: null,
        awardImage: null,
        probability: null,
        isEntity: null,
      },
      loading: false,
      // typeList: [
      //   { name: '商品', value: '1' },
      //   { name: '活动', value: '2' },
      // ],
      // bannerPositionList: [{ name: '首页轮播图', value: '1' }],
      // statusList: [
      //   { name: '隐藏', value: '0' },
      //   { name: '显示', value: '1' },
      // ],
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        console.log(newVal, this.itemId)
        newVal && this.itemId && this.getBanner()
      },
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async (valid, fields) => {
        if (!valid) return false
        this.loading = true
        const params = {
          awardNameSimple: this.formData.awardNameSimple,
          awardImage: this.formData.awardImage,
          integral: this.formData.integral,
          probability: this.formData.probability,
          isEntity: this.formData.isEntity,
        }
        try {
          let res = null
          if (this.itemId) {
            params.id = this.formData.id
            res = await updateLuckyDrawRotaryTable(params)
          } else {
            res = await addBanner(params)
          }
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('reload')
          this.handleClose()
        } catch (e) {
          this.loading = false
        }
      })
    },
    async getBanner() {
      this.loading = true
      try {
        const res = await getLuckyDrawRotaryTableById(this.itemId)
        this.loading = false
        // res.data.awardNameSimple = String(res.data.awardNameSimple)
        this.oldList = [res.data.awardImage]
        this.formData = res.data
        // this.formData = {
        //   ...this.formData,
        //   ...Object.keys(this.formData).reduce(
        //     (acc, key) =>
        //       key in res.data ? { ...acc, [key]: res.data[key] } : acc,
        //     {}
        //   ),
        // }
      } catch (e) {
        this.loading = false
      }
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.formData = {}
      this.oldList = []
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style></style>
