<template>
  <el-dialog
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form
      :model="formData"
      ref="form"
      label-width="120px"
      size="mini"
      v-loading="loading"
    >
      <el-form-item prop="bannerImg" label="轮播图">
        <div>
          <template>
            <el-image
              style="width: 100px; height: 100px"
              :src="formData.awardImage"
              fit="cover"
              :preview-src-list="[formData.awardImage]"
            />
          </template>
        </div>
      </el-form-item>
      <!-- <el-form-item prop="bannerUrl" label="跳转地址">
        <div> {{formData.bannerUrl}} </div>
      </el-form-item>
      <el-form-item prop="bannerType" label="类型">
        <div> {{formData.bannerType | filterType}} </div>
      </el-form-item>
      <el-form-item prop="position" label="banner位置">
        <div> {{formData.position == 1 ? '首页轮播图':''}} </div>
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <div> {{formData.status | filterStatus}} </div>
      </el-form-item>
      <el-form-item prop="sort" label="排序">
        <div> {{formData.sort}} </div>
      </el-form-item> -->
      <el-form-item prop="awardNameSimple" label="奖项名称" verify>
        <el-input
          v-model.number="formData.awardNameSimple"
          placeholder="请输入奖项名称"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item prop="integral" label="喜糖数" verify>
        <el-input
          v-model.number="formData.integral"
          placeholder="请输入奖项名称"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item prop="probability" label="中奖概率" verify>
        <el-input
          v-model.number="formData.probability"
          placeholder="请输入奖项名称"
          readonly
        ></el-input>
      </el-form-item>

      <el-form-item prop="awardNameSimple" label="是否实体奖品" verify>
        <span>{{ formData.isEntity == 1 ? '实体奖品' : '喜糖奖励' }}</span>
      </el-form-item>
    </el-form>
    <gl-flex>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import { getLuckyDrawRotaryTableById } from '@/api/coox/lottery'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        console.log(newVal, this.itemId)
        newVal && this.itemId && this.getBanner()
      },
    },
  },
  methods: {
    async getBanner() {
      this.loading = true
      try {
        const res = await getLuckyDrawRotaryTableById(this.itemId)
        this.loading = false
        res.data.awardNameSimple = String(res.data.awardNameSimple)
        // res.data.position = String(res.data.position)
        // res.data.status = String(res.data.status)
        this.oldList = [res.data.awardImage]
        this.formData = res.data
      } catch (e) {
        this.loading = false
      }
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped></style>
