import request from '@/plugins/request'

//系统设置-协议文本管理




//系统设置-轮播图管理-列表
export function bannerList(data = {}){
  return request({
    url: `/api/system/banner/getBannerList`,
    method: 'POST',
    data: data,
  })
}
//系统设置-轮播图管理-详情
export function getBannerById(bannerId){
  return request({
    url: `/api/system/banner/get?id=${bannerId}`,
    method: 'GET',
  })
}
//系统设置-轮播图管理-添加
export function addBanner(data = {}){
  return request({
    url: `/api/system/banner/addBanner`,
    method: 'POST',
    data: data,
  })
}
//系统设置-轮播图管理-修改
export function updateBanner(data = {}){
  return request({
    url: `/api/system/banner/updateBanner`,
    method: 'POST',
    data: data,
  })
}




//系统设置-公告管理-列表
export function noticeList(data = {}){
  return request({
    url: `/api/system/sysnotice/getNoticeList`,
    method: 'POST',
    data: data,
  })
}

//系统设置-公告管理-新增
export function addNotice(data = {}){
  return request({
    url: `/api/system/sysnotice/addNotice`,
    method: 'POST',
    data: data,
  })
}
//系统设置-公告管理-修改
export function updateNotice(data = {}){
  return request({
    url: `/api/system/sysnotice/updateNotice`,
    method: 'POST',
    data: data,
  })
}
//系统设置-公告管理-详情
export function noticeById(itemId){
  return request({
    url: `/api/system/sysnotice/getNotice`,
    method: 'GET',
    params: {noticeId: itemId}
  })
}




export default {
  //协议文本管理

  //轮播图管理
  bannerList,
  getBannerById,
  addBanner,
  updateBanner,

  //公告管理
  noticeList,
  addNotice,
  updateNotice,
  noticeById,
}


